import React from 'react';

import Container from '../Container/Container';

import styles from './PageTitle.module.css';

const PageTitle = ({ children, title }) => {
  return (
    <div className={styles.root}>
      <Container size="large">
        <h1 className="h6 text-left">{title}</h1>
        {children && <div className={styles.subtitle}>{children}</div>}
      </Container>
    </div>
  );
};

export default PageTitle;
