/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"

import Layout from "../components/organisms/Layout/Layout"
import SEO from "../components/organisms/Seo/Seo"
import Container from "../components/atoms/Container/Container"
import PageTitle from "../components/atoms/PageTitle/PageTitle"
import Button from "../components/atoms/Button/Button"
import Callout from "../components/molecules/Callout/Callout"

import styles from "./styleguide.module.css"

const StyleGuide = () => {
    return (
        <Layout>
            <SEO title="Style Guide" />
            <Container>
                <PageTitle title="Style Guide" />

                <div className={styles.styleExample}>
                    <h1>Heading 1</h1>
                    <code>&lt;h1&gt;Heading 1&lt;/h1&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <h2>Heading 2</h2>
                    <code>&lt;h2&gt;Heading 2&lt;/h2&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <h3>Heading 3</h3>
                    <code>&lt;h3&gt;Heading 3&lt;/h3&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <h4>Heading 4</h4>
                    <code>&lt;h4&gt;Heading 4&lt;/h4&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <h5>Heading 5</h5>
                    <code>&lt;h5&gt;Heading 5&lt;/h5&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <h6>Heading 6</h6>
                    <code>&lt;h6&gt;Heading 6&lt;/h6&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <p className="subtitle">Subtitle Text</p>
                    <code>&lt;p class="subtitle"&gt;Subtitle Text&lt;/p&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <p className="subtitle small">Small Subtitle Text</p>
                    <code>&lt;p class="subtitle small"&gt;Small Subtitle Text&lt;/p&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <p>Standard Body Copy</p>
                    <code>&lt;p&gt;Standard body copy&lt;/p&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <p className="small">Small Body Copy</p>
                    <code>&lt;p class="small"&gt;Small body copy&lt;/p&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <label>Form Label</label>
                    <code>&lt;label&gt;Form label&lt;/label&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <span className="overline">Overline</span>
                    <code>&lt;span class="overline"&gt;Overline&lt;/span&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <Button type="a" level="primary" href="#">Primary Button</Button>
                    {/* <code>&lt;a href="#" class="button button--primary"&gt;Primary button&lt;/a&gt;</code> */}
                </div>
                <div className={styles.styleExample}>
                    <Button type="a" level="primary" size="small" href="#">Primary Small Button</Button>
                    {/* <code>&lt;a href="#" class="button button--primary"&gt;Primary button&lt;/a&gt;</code> */}
                </div>
                <div className={styles.styleExample}>
                    <Button type="a" level="primary" size="smallest" href="#">Primary Smallest Button</Button>
                    {/* <code>&lt;a href="#" class="button button--primary"&gt;Primary button&lt;/a&gt;</code> */}
                </div>
                <div className={`${styles.styleExample} ${styles.color}`}>
                    <Button type="a" level="primary" alternate={true} href="#">Alternate Primary Button</Button>
                    {/* <code>&lt;a href="#" class="button button--primary"&gt;Primary button&lt;/a&gt;</code> */}
                </div>
                <div className={styles.styleExample}>
                    <Button type="a" level="primary" href="#" disabled={true}>Disabled Primary Button</Button>
                    {/* <code>&lt;a href="#" class="button button--primary disabled" disabled&gt;Disabled primary button&lt;/a&gt;</code> */}
                </div>
                <div className={styles.styleExample}>
                    <Button type="a" level="secondary" href="#">Secondary Button</Button>
                    {/* <code>&lt;a href="#" class="button button--primary"&gt;Primary button&lt;/a&gt;</code> */}
                </div>
                <div className={styles.styleExample}>
                    <Button type="a" level="secondary" size="small" href="#">Secondary Small Button</Button>
                    {/* <code>&lt;a href="#" class="button button--primary"&gt;Primary button&lt;/a&gt;</code> */}
                </div>
                <div className={styles.styleExample}>
                    <Button type="a" level="secondary" size="smallest" href="#">Secondary Smallest Button</Button>
                    {/* <code>&lt;a href="#" class="button button--primary"&gt;Primary button&lt;/a&gt;</code> */}
                </div>
                <div className={`${styles.styleExample} ${styles.color}`}>
                    <Button type="a" level="secondary" alternate={true} href="#">Alternate Secondary Button</Button>
                    {/* <code>&lt;a href="#" class="button button--primary"&gt;Primary button&lt;/a&gt;</code> */}
                </div>
                <div className={styles.styleExample}>
                    <Button type="a" level="secondary" href="#" disabled={true}>Disabled Secondary Button</Button>
                    {/* <code>&lt;a href="#" class="button button--primary disabled" disabled&gt;Disabled primary button&lt;/a&gt;</code> */}
                </div>
                <div className={styles.styleExample}>
                    <Callout design="starburst" color="honey">Starburst Style</Callout>
                    {/* <code>&lt;a href="#" class="button button--primary disabled" disabled&gt;Disabled primary button&lt;/a&gt;</code> */}
                </div>
                <div className={styles.styleExample}>
                    <Callout design="starburst2" color="red">Starburst2 Style</Callout>
                    {/* <code>&lt;a href="#" class="button button--primary disabled" disabled&gt;Disabled primary button&lt;/a&gt;</code> */}
                </div>
                <div className={styles.styleExample}>
                    <Callout design="tag" color="blue">Tag Style</Callout>
                    {/* <code>&lt;a href="#" class="button button--primary disabled" disabled&gt;Disabled primary button&lt;/a&gt;</code> */}
                </div>
                <div className={styles.styleExample}>
                    <Callout design="tag2" color="teal">Tag2 Style</Callout>
                    {/* <code>&lt;a href="#" class="button button--primary disabled" disabled&gt;Disabled primary button&lt;/a&gt;</code> */}
                </div>
            </Container>
        </Layout>
    )
  }
  
  export default StyleGuide
  